import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Doc from "../../../components/Doc"

const Law = () => (
  <Layout mode={`default`}>
    <SEO title={`特定商取引法に基づく表記`} />
    <div className={`l-wrap`}>
      <Doc>
        <div id="law">
          <h2>特定商取引法に基づく表記</h2>
          <h3>事業者の名称</h3>
          <p>TRANS BOOKS DOWNLOADs 萩原俊矢</p>
          <h3>事業者の連絡先</h3>
          <p>電話番号 ： 050-5436-1228</p>
          <p>E-mail ： transbooks.info [at-mark] gmail.com</p>
          <p>定休日：不定休</p>
          <h3>販売価格について</h3>
          <p>販売価格は、表示された金額（表示価格/消費税込）と致します。</p>
          <h3>代金（対価）の支払方法と時期</h3>
          <p>
            支払方法： Gumroad での決済となります。クレジットカードと Paypal が利用できます。
          </p>
          <h3>役務または商品の引渡時期</h3>
          <p>
            購入を受けてから5日以内にダウンロードリンク等の発行を行います。詳しくは Gumroad に従います。
          </p>
          <h3>返品についての特約に関する事項</h3>
          <p>データ販売という特性上、基本的には返品には応じません。</p>
          <h3>事業者の所在地</h3>
          <p>郵便番号 ：150-0002</p>
          <p>住所 ：東京都渋谷区渋谷 3-2-1#4</p>
        </div>
      </Doc>
    </div>
  </Layout>
)

export default Law
